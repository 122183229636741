// palette for EuroFortune Casino
$bg-color: #102368;
$text-color: #fff;
$primary: #ffcc33;
$success: #33ff66;
$info: #D5B34E;
$danger: #FF3356;



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -5px;
    margin-bottom: -5px;
    max-width: 200px !important;
  }
}


.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}


#promos {
  img {
    width: 150% !important;
  }
}
